module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FFFFFF","display":"standalone","icon":"src/base/assets/favicons/icon.png","name":"YouArePreApproved","short_name":"YAPA","start_url":"/","theme_color":"#0096D7","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3f509408e7be113072a9ebb7922df17"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
