import { fromJS } from 'immutable';

export const loadState = () => {
  try {
    const serializedState =
      typeof window !== 'undefined' ? sessionStorage.getItem('state') : null;

    if (serializedState === null) {
      return undefined;
    }

    return fromJS(JSON.parse(serializedState));
  } catch (error) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);

    sessionStorage.setItem('state', serializedState);
  } catch (error) {
    return undefined;
  }
};
