import { createSelector } from 'reselect';

import * as constants from './constants';

export const yapaSelector = state => state.get(constants.REDUCER_NAME);

export const getApplication = createSelector([yapaSelector], state =>
  state.get(constants.APPLICATION).toJS()
);

export const getAreRatesOutdated = createSelector([yapaSelector], state =>
  state.get(constants.ARE_RATES_OUTDATED)
);

export const getIDR = createSelector([yapaSelector], state =>
  state.get(constants.IDR)
);

export const getILR = createSelector([yapaSelector], state =>
  state.get(constants.ILR)
);

export const getIRR = createSelector([yapaSelector], state =>
  state.get(constants.IRR)
);

export const getError = createSelector([yapaSelector], state =>
  state.get(constants.ERROR).toJS()
);

export const getExpiration = createSelector([yapaSelector], state =>
  state.get(constants.EXPIRATION).toJS()
);

export const getLoading = createSelector([yapaSelector], state =>
  state.get(constants.LOADING).toJS()
);

export const getOfferDetails = createSelector([yapaSelector], state =>
  state.get(constants.OFFER_DETAILS).toJS()
);

export const getOfferId = createSelector([yapaSelector], state =>
  state.get(constants.OFFER_ID)
);

export const getOfferWeb = createSelector([yapaSelector], state =>
  state.get(constants.OFFER_WEB)
);

export const getOfferIndex = createSelector([yapaSelector], state =>
  state.get(constants.OFFER_INDEX)
);

export const getPerson = createSelector([yapaSelector], state =>
  state.get(constants.PERSON).toJS()
);

export const getProductId = createSelector([yapaSelector], state =>
  state.get(constants.PRODUCT_ID)
);

export const getRates = createSelector([yapaSelector], state =>
  state.get(constants.RATES).toJS()
);

export const getRatesParams = createSelector([yapaSelector], state =>
  state.get(constants.RATES_PARAMS).toJS()
);

export const getRefreshUpdate = createSelector([yapaSelector], state =>
  state.get(constants.REFRESH_UPDATE)
);

export const getResNum = createSelector([yapaSelector], state =>
  state.get(constants.RES_NUM)
);

export const getRequestId = createSelector([yapaSelector], state =>
  state.get(constants.REQUEST_ID)
);

export const getSuccess = createSelector([yapaSelector], state =>
  state.get(constants.SUCCESS).toJS()
);

export const getUpdate = createSelector([yapaSelector], state =>
  state.get(constants.UPDATE).toJS()
);

export const getButtonLoading = createSelector([yapaSelector], state =>
  state.get(constants.BUTTON_LOADING)
);
