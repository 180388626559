import { action } from 'typesafe-actions';
import { fromJS } from 'immutable';

import * as constants from './constants';

// Simple Actions
export const reset = () => {
  return action(constants.RESET);
};

export const setApplication = application =>
  action(constants.SET_APPLICATION, fromJS(application));

export const setAreRatesOutdated = timeout =>
  action(constants.SET_ARE_RATES_OUTDATED, timeout);

export const setIDR = IDR => action(constants.SET_IDR, IDR);

export const setILR = ILR => action(constants.SET_ILR, ILR);

export const setIRR = IRR => action(constants.SET_IRR, IRR);

export const setError = error => action(constants.SET_ERROR, fromJS(error));

export const setButtonLoading = loading =>
  action(constants.SET_BUTTON_LOADING, fromJS(loading));

export const setExpiration = expiration =>
  action(constants.SET_EXPIRATION, fromJS(expiration));

export const setLoading = loading =>
  action(constants.SET_LOADING, fromJS(loading));

export const setOfferDetails = offerDetails =>
  action(constants.SET_OFFER_DETAILS, fromJS(offerDetails));

export const setOfferId = offerId => action(constants.SET_OFFER_ID, offerId);

export const setOfferWeb = offerWeb =>
  action(constants.SET_OFFER_WEB, offerWeb);

export const setOfferIndex = offerIndex =>
  action(constants.SET_OFFER_INDEX, offerIndex);

export const setPerson = person => action(constants.SET_PERSON, fromJS(person));

export const setProductId = productId =>
  action(constants.SET_PRODUCT_ID, productId);

export const setRates = rates => {
  const filteredRates = rates.filter(
    offer => offer.Name !== 'FHA Refi Rate-Term'
  );

  return action(constants.SET_RATES, fromJS(filteredRates));
};

export const setRatesParams = ratesParams =>
  action(constants.SET_RATES_PARAMS, fromJS(ratesParams));

export const setRefreshUpdate = refreshUpdate =>
  action(constants.SET_REFRESH_UPDATE, refreshUpdate);

export const setResNum = resNum => action(constants.SET_RES_NUM, resNum);

export const setRequestId = requestId =>
  action(constants.SET_REQUEST_ID, requestId);

export const setSuccess = success =>
  action(constants.SET_SUCCESS, fromJS(success));

export const setUpdate = update => action(constants.SET_UPDATE, fromJS(update));

// Saga Actions
export const lookupQuery = customer =>
  action(constants.SAGA_LOOKUP_QUERY, customer);

export const resNumRatesQuery = customer =>
  action(constants.SAGA_RESNUM_RATES_QUERY, customer);

export const ratesQuery = rateParams =>
  action(constants.SAGA_RATES_QUERY, rateParams);

export const validateZipQuery = params =>
  action(constants.SAGA_VALIDATE_ZIP_QUERY, params);

export const detailsQuery = productId =>
  action(constants.SAGA_DETAILS_QUERY, productId);

export const applicationQuery = appData =>
  action(constants.SAGA_APPLICATION_QUERY, appData);
