export const REDUCER_NAME = 'YAPA';

// State Variables
export const APPLICATION = 'application';
export const ARE_RATES_OUTDATED = 'areRatesOutdated';
export const IDR = 'IDR';
export const ILR = 'ILR';
export const IRR = 'IRR';
export const ERROR = 'error';
export const EXPIRATION = 'expiration';
export const LOADING = 'loading';
export const OFFER_DETAILS = 'offerDetails';
export const OFFER_ID = 'offerId';
export const OFFER_WEB = 'offerWeb';
export const OFFER_INDEX = 'offerIndex';
export const PERSON = 'person';
export const PRODUCT_ID = 'productId';
export const RATES = 'rates';
export const RATES_PARAMS = 'ratesParams';
export const REFRESH_UPDATE = 'refreshUpdate';
export const RES_NUM = 'resNum';
export const REQUEST_ID = 'requestId';
export const SUCCESS = 'success';
export const UPDATE = 'update';
export const BUTTON_LOADING = 'buttonLoading';

// Action Types
export const RESET = `store/RESET`;
export const SET_APPLICATION = 'store/SET_APPLICATION';
export const SET_ARE_RATES_OUTDATED = 'store/SET_ARE_RATES_OUTDATED';
export const SET_IDR = 'store/SET_IDR';
export const SET_ILR = 'store/SET_ILR';
export const SET_IRR = 'store/SET_IRR';
export const SET_ERROR = 'store/SET_ERROR';
export const SET_EXPIRATION = 'store/SET_EXPIRATION';
export const SET_LOADING = 'store/SET_LOADING';
export const SET_OFFER_DETAILS = 'store/SET_OFFER_DETAILS';
export const SET_OFFER_ID = 'store/SET_OFFER_ID';
export const SET_OFFER_WEB = 'store/SET_OFFER_WEB';
export const SET_OFFER_INDEX = 'store/SET_OFFER_INDEX';
export const SET_PERSON = 'store/SET_PERSON';
export const SET_PRODUCT_ID = 'store/SET_PRODUCT_ID';
export const SET_RATES = 'store/SET_RATES';
export const SET_RATES_PARAMS = 'store/SET_RATES_PARAMS';
export const SET_REFRESH_UPDATE = 'store/SET_REFRESH_UPDATE';
export const SET_RES_NUM = 'store/SET_RES_NUM';
export const SET_REQUEST_ID = 'store/SET_REQUEST_ID';
export const SET_SUCCESS = 'store/SET_SUCCESS';
export const SET_UPDATE = 'store/SET_UPDATE';
export const SET_BUTTON_LOADING = 'store/SET_BUTTON_LOADING';

// Saga Action Types
export const SAGA_APPLICATION_QUERY = 'store/saga/APPLICATION_QUERY';
export const SAGA_LOOKUP_QUERY = 'store/saga/LOOKUP_QUERY';
export const SAGA_RESNUM_RATES_QUERY = 'store/saga/RESNUM_RATES_QUERY';
export const SAGA_VALIDATE_ZIP_QUERY = 'store/saga/SAGA_VALIDATE_ZIP_QUERY';
export const SAGA_RATES_QUERY = 'store/saga/RATES_QUERY';
export const SAGA_DETAILS_QUERY = 'store/saga/DETAILS_QUERY';
