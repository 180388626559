import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import subMinutes from 'date-fns/subMinutes';
import wait from 'waait';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { formatDate } from 'base/utils/formatDate';

import {
  SAGA_APPLICATION_QUERY,
  SAGA_DETAILS_QUERY,
  SAGA_LOOKUP_QUERY,
  SAGA_RESNUM_RATES_QUERY,
  SAGA_RATES_QUERY,
  SAGA_VALIDATE_ZIP_QUERY,
} from './constants';
import {
  setApplication,
  setError,
  setExpiration,
  setILR,
  setIRR,
  setLoading,
  setOfferDetails,
  setOfferId,
  setOfferWeb,
  setPerson,
  setRates,
  setRatesParams,
  setRequestId,
  setResNum,
  setSuccess,
  setUpdate,
  setButtonLoading,
} from './actions';
import {
  apiLookUp,
  apiLookUpQuery,
  apiRates,
  apiRatesQuery,
  apiResNumRates,
  apiResNumRatesQuery,
  apiDetails,
  apiApplication,
  apiValidateZipQuery,
} from './requests';
import { getUpdate } from './selectors';

function* lookupQueryWorker(action) {
  const {
    creative,
    dataset,
    source,
    resNum,
    firstName,
    lastName,
    zip,
  } = action.payload;

  try {
    yield all([
      put(setError({ type: 'lookup', isError: false })),
      put(setSuccess({ type: 'lookup', didSucceed: false })),
      put(setLoading({ type: 'lookup', isLoading: true })),
      put(setResNum(resNum?.trim())),
      put(setPerson({ ResNum: resNum, Zip: zip })),
    ]);

    const response = yield apiLookUp(
      creative,
      dataset,
      source,
      resNum,
      firstName,
      lastName,
      zip
    );
    yield wait(1000);
    const query = yield apiLookUpQuery(response.RequestId);

    if (response.Error) {
      yield put(setError({ type: 'lookup', isError: true }));
    } else if (query.Error) {
      yield put(setError({ type: 'lookup', isError: true }));
    } else {
      if (query.RequestId) yield put(setRequestId(query.RequestId));
      yield put(setOfferId(query.OfferId));
      yield put(setOfferWeb(query.OfferWeb));
      yield put(setRatesParams(query));
      yield put(setRates([]));
      yield put(setSuccess({ type: 'lookup', didSucceed: true }));
      yield put(setResNum(query?.ResNum.trim()));
    }
  } catch (err) {
    yield put(setError({ type: 'lookup', isError: true }));
  } finally {
    yield put(setILR(true));
    yield put(setIRR(false));
    yield put(setLoading({ type: 'lookup', isLoading: false }));
  }
}

export function* lookupQueryWatcher() {
  yield takeLatest(SAGA_LOOKUP_QUERY, lookupQueryWorker);
}

function* ratesQueryWorker(action) {
  const date = new Date();
  const newDate = formatISO(date);
  const update = yield select(getUpdate);
  const { queryType } = action.payload;

  try {
    yield all([
      put(setError({ type: 'rates', isError: false })),
      put(setSuccess({ type: 'rates', didSucceed: false })),
      put(setLoading({ type: queryType || 'rates', isLoading: true })),
      put(setRatesParams(action.payload)),
    ]);
    const response = yield apiRates(action.payload);

    yield wait(2000);

    if (response.Error) {
      yield put(setError({ type: 'rates', isError: true }));
      return;
    }

    const query = yield apiRatesQuery(response.RequestId);
    if (query.Error) {
      yield put(setError({ type: 'rates', isError: true }));
    } else {
      yield put(
        setUpdate({
          ...update,
          date: date,
          fiveAhead: addMinutes(date, 5),
          fiveBehind: subMinutes(date, 5),
          iso: newDate,
          relative: formatDate().relative,
          standard: format(date, 'PPpp'),
          tenAhead: addMinutes(date, 10),
          tenBehind: subMinutes(date, 10),
        })
      );
      yield put(
        setExpiration({
          lock: query.lock,
          timeout: query.timeout,
        })
      );

      if (query.RequestId) yield put(setRequestId(query.RequestId));
      if (query.offers.length !== 0) {
        const sortedOffers = query.offers.sort((a, b) => {
          let A = parseInt(a.AmortTerm);
          let B = parseInt(b.AmortTerm);

          switch (true) {
            case A < B:
              return 1;
            case A === B:
              return 0;
            case A > B:
              return -1;
            default:
              return 1;
          }
        });

        yield put(setRates(sortedOffers));
      } else {
        yield put(setRates([]));
      }
      yield put(setSuccess({ type: 'rates', didSucceed: true }));
    }
  } catch (err) {
    yield put(setError({ type: 'rates', isError: true }));
  } finally {
    yield put(setILR(false));
    yield put(setIRR(true));
    yield put(setLoading({ type: 'rates', isLoading: false }));
  }
}

export function* ratesQueryWatcher() {
  yield takeLatest(SAGA_RATES_QUERY, ratesQueryWorker);
}

function* detailsQueryWorker(action) {
  try {
    yield all([
      put(setError({ type: 'details', isError: false })),
      put(setSuccess({ type: 'details', didSucceed: false })),
      put(setLoading({ type: 'details', isLoading: true })),
    ]);

    const response = yield apiDetails(action.payload);

    if (response.Error) {
      yield put(setError({ type: 'details', isError: true }));
    } else {
      yield put(setOfferDetails(response));
      yield put(setSuccess({ type: 'details', didSucceed: true }));
    }
  } catch (err) {
    yield put(setError({ type: 'details', isError: true }));
  } finally {
    yield put(setLoading({ type: 'details', isLoading: false }));
  }
}

export function* detailsQueryWatcher() {
  yield takeLatest(SAGA_DETAILS_QUERY, detailsQueryWorker);
}

function* applicationQueryWorker(action) {
  const { email, password, phone, offerId, productId } = action.payload;

  try {
    yield all([
      put(setError({ type: 'application', isError: false })),
      put(setSuccess({ type: 'application', didSucceed: false })),
      put(setLoading({ type: 'application', isLoading: true })),
    ]);

    const response = yield apiApplication(
      email,
      password,
      phone,
      offerId,
      productId
    );

    if (response.Error) {
      yield put(setError({ type: 'application', isError: true }));
    } else {
      yield put(setRequestId(response.RequestId));
      yield put(
        setApplication({ RequestId: response.RequestId, URL: response.Url })
      );
      yield put(setSuccess({ type: 'application', didSucceed: true }));
    }
  } catch (err) {
    yield put(setError({ type: 'application', isError: true }));
  } finally {
    yield put(setLoading({ type: 'application', isLoading: false }));
  }
}

export function* applicationQueryWatcher() {
  yield takeLatest(SAGA_APPLICATION_QUERY, applicationQueryWorker);
}

function* resNumRatesQueryWorker(action) {
  const { creative, dataset, source, resNum, zip } = action.payload;

  try {
    yield all([
      put(setError({ type: 'resNumRates', isError: false })),
      put(setSuccess({ type: 'resNumRates', didSucceed: false })),
      put(setLoading({ type: 'resNumRates', isLoading: true })),
      put(setResNum(resNum?.trim())),
      put(setPerson({ ResNum: resNum, Zip: zip })),
    ]);

    const response = yield apiResNumRates(
      creative,
      zip,
      dataset,
      source,
      resNum
    );
    if (zip) {
      const query = yield apiResNumRatesQuery(response.RequestId);

      if (query.Error) {
        yield put(setError({ type: 'resNumRates', isError: true }));
        yield put(setRatesParams({}));
      }

      yield put(setOfferId(query.OfferId));
      yield put(setOfferWeb(query.OfferWeb));
      yield put(setRatesParams(query));
      yield put(setResNum(query.ResNum?.trim()));
    }
    if (response.Error) {
      yield put(setError({ type: 'resNumRates', isError: true }));
      yield put(setRatesParams({}));
    } else {
      if (response.RequestId) yield put(setRequestId(response.RequestId));

      yield put(setRates([]));
      yield put(setSuccess({ type: 'resNumRates', didSucceed: true }));
    }
  } catch (err) {
    yield put(setError({ type: 'resNumRates', isError: true }));
  } finally {
    yield put(setILR(true));
    yield put(setIRR(false));
    yield put(setLoading({ type: 'resNumRates', isLoading: false }));
  }
}

function* validateZipQueryWorker({ payload }) {
  const { requestId, resNum, zip } = payload;

  try {
    yield put(setLoading({ type: 'zip', isLoading: true }));
    yield put(setSuccess({ type: 'zip', didSucceed: false }));
    yield put(setButtonLoading(true));

    const response = yield apiValidateZipQuery(requestId, resNum, zip);

    if (response?.toLowerCase() === 'valid') {
      const query = yield apiResNumRatesQuery(requestId);

      if (query.Error) {
        yield put(setError({ type: 'resNumRates', isError: true }));
        yield put(setRatesParams({}));
      }

      yield put(setOfferId(query.OfferId));
      yield put(setOfferWeb(query.OfferWeb));
      yield put(setRatesParams(query));
      yield put(setResNum(query.ResNum?.trim()));
      yield put(setButtonLoading(false));
      yield put(setSuccess({ type: 'zip', didSucceed: true }));
    } else {
      yield put(setError({ type: 'zip', isError: true }));
    }
  } catch (error) {
    put(setError({ type: 'zip', isError: true }));
  } finally {
    put(setLoading({ type: 'zip', isLoading: false }));
  }
}

export function* resNumRatesQueryWatcher() {
  yield takeLatest(SAGA_RESNUM_RATES_QUERY, resNumRatesQueryWorker);
}

export function* validateZipQueryWatcher() {
  yield takeLatest(SAGA_VALIDATE_ZIP_QUERY, validateZipQueryWorker);
}

export default function* sagas() {
  yield all([
    lookupQueryWatcher(),
    resNumRatesQueryWatcher(),
    ratesQueryWatcher(),
    detailsQueryWatcher(),
    applicationQueryWatcher(),
    validateZipQueryWatcher(),
  ]);
}
