import addMinutes from 'date-fns/addMinutes';
import wait from 'waait';
import {
  browserName,
  engineName,
  fullBrowserVersion,
  osName,
  osVersion,
} from 'react-device-detect';

// Config
const urls = {
  app: `${process.env.GATSBY_API_ENDPOINT}Application`,
  appQuery: `${process.env.GATSBY_API_ENDPOINT}ApplicationQuery`,
  lookUp: `${process.env.GATSBY_API_ENDPOINT}LookUp`,
  lookUpQuery: `${process.env.GATSBY_API_ENDPOINT}LookUpQuery`,
  rates: `${process.env.GATSBY_API_ENDPOINT}Rates`,
  ratesQuery: `${process.env.GATSBY_API_ENDPOINT}RatesQuery`,
  details: `${process.env.GATSBY_API_ENDPOINT}Offers`,
  resNumRates: `${process.env.GATSBY_API_ENDPOINT}ResNumRates`,
  resNumRatesEx: `${process.env.GATSBY_API_ENDPOINT}ResNumPreZip`,
  resNumRatesQuery: `${process.env.GATSBY_API_ENDPOINT}ResNumRatesQuery`,
  resNumRatesExQuery: `${process.env.GATSBY_API_ENDPOINT}ResNumPreZipQuery`,
  validateZipQuery: `${process.env.GATSBY_API_ENDPOINT}ResNumZipCheck`,
};

const auth = {
  AccountId: process.env.GATSBY_API_ACCOUNT_ID,
  AccountKey: process.env.GATSBY_API_ACCOUNT_KEY,
};

const deviceDetails = `${osName} ${osVersion} — ${browserName}/${engineName} ${fullBrowserVersion}`;

// Request Body
const appConfig = (Email, Password, Phone, OfferId, ProductId) => ({
  Authentication: auth,
  Email,
  OfferId,
  Password,
  Phone,
  ProductId,
  Wait: true,
});

const baseQueryConfig = RequestId => ({
  Authentication: auth,
  RequestId,
});

const detailsConfig = ProductId => ({
  Authentication: auth,
  ProductId,
});

const zipQueryConfig = (requestId, resNum, zip) => ({
  Authentication: auth,
  RequestId: requestId,
  ResNum: resNum,
  Zip: zip,
});

const lookUpConfig = (
  Creative,
  Dataset,
  Source,
  ResNum,
  FirstName,
  LastName,
  Zip
) => {
  const prefix = process.env.GATSBY_SOURCE === 'development' ? 'Dev-' : '';

  return {
    Authentication: auth,
    Entry: {
      Creative: Creative || 'None',
      Dataset: Dataset || 'All',
      Source: prefix + (Source || 'Web'),
      UserAgent: deviceDetails,
    },
    Person: {
      ResNum,
      FirstName,
      LastName,
      Zip,
    },
  };
};

const ratesConfig = data => ({
  Authentication: auth,
  RequestId: data.RequestId,
  OfferId: data.OfferId,
  PropertyType: data.PropertyType,
  LoanPurpose: data.LoanPurpose,
  LoanAmount: data.LoanAmount,
  CashOut: data.LoanPurpose === 'Refi' ? data.CashOut : undefined,
  Fico: data.Fico || '',
  Veteran: data.Veteran,
  Zip: data.Zip,
  PropertyValue: data.PropertyValue,
  DwellingType: data.DwellingType,
  LoanProgram: data.AmortTerm, // LoanProgram in request but AmortTerm in response
  Points: `${parseInt(data.Points) + 0.25}`,
});

const resNumRatesConfig = (Creative, zip, Dataset, Source, ResNum) => {
  const prefix = process.env.GATSBY_SOURCE === 'development' ? 'Dev-' : '';

  return {
    Authentication: auth,
    Entry: {
      Creative: Creative || 'None',
      Dataset: Dataset || 'All',
      Source: prefix + (Source || 'Web'),
      UserAgent: deviceDetails,
    },
    Person: {
      ResNum,
      zip,
    },
    Wait: true,
  };
};

/**
 * Matchs loan term type and prepends the corresponding prefix
 * @function
 *
 * @param   {string} term loan term years
 * @returns {string}      term with yr/arm string prepended
 */
// const formatTerm = term => {
//   const yearsRgx = new RegExp(/(30|15|10)/g);
//   const armRgx = new RegExp(/(71|51|31)/g);

//   if (term.match(yearsRgx)) {
//     return term.replace(yearsRgx, 'Yr$1');
//   }
//   if (term.match(armRgx)) {
//     return term.replace(armRgx, 'Arm$1');
//   }

//   return null;
// };

/**
 * YAPA authentication keys
 * @typedef  {Object} Auth
 * @property {string} AccountId
 * @property {string} AccountKey
 */

/**
 * Base request payload
 * @typedef  {Object} QueryBody
 * @property {Auth}   Authentication
 * @property {string} RequestId
 */

/**
 * LookUpQuery response formatted for Rates payload
 * @typedef  {Object}  QueryParams
 * @property {string}  CallNumber
 * @property {string}  CashOut
 * @property {string}  DwellingType
 * @property {string}  Fico
 * @property {string}  FirstName
 * @property {string}  Hours
 * @property {string}  LastName
 * @property {string}  License
 * @property {string}  LoanAmount
 * @property {string}  AmortTerm
 * @property {string}  LoanPurpose
 * @property {string}  OfferId
 * @property {string}  Points
 * @property {string}  PropertyType
 * @property {string}  PropertyValue
 * @property {string}  RequestId
 * @property {boolean} Veteran
 * @property {string}  Zip
 */

/**
 * RatesQuery offer object body
 * @typedef  {Object} RatesProduct
 * @property {string} Lender
 * @property {string} ProductId
 * @property {string} Name
 * @property {number} HomeValue
 * @property {number} CashOut
 * @property {number} LoanAmount
 * @property {number} CashOut
 * @property {number} Points
 * @property {string} AmortTerm
 * @property {number} Apr
 * @property {number} ClosingCosts
 * @property {number} Price
 * @property {number} Rate
 * @property {number} DiscountRebate
 * @property {number} PandI
 * @property {number} MonthlyMI
 * @property {number} TotalPayment
 */

/**
 * RatesQuery response
 * @typedef  {Object} RatesData
 * @property {RatesProduct[]} offers
 * @property {string} lock
 * @property {string} timeout
 */

/**
 * Application response
 * @typedef  {Object} AppResponse
 * @property {string} RequestId
 * @property {string} Url
 */

/**
 * LookUp endpoint get RequestId.
 * @function
 *
 * @param {string} creative  request entry creative
 * @param {string} dataset   request entry dataset
 * @param {string} source    request source
 * @param {string} resNum    customer approval number
 * @param {string} firstName customer first name
 * @param {string} lastName  customer last name
 * @param {string} zip       customer zip code
 *
 * @return {QueryBody} LookUpQuery payload
 */
export const apiLookUp = async (
  creative,
  dataset,
  source,
  resNum,
  firstName,
  lastName,
  zip
) => {
  try {
    const response = await fetch(urls.lookUp, {
      method: 'POST',
      body: JSON.stringify(
        lookUpConfig(
          creative,
          dataset,
          source,
          resNum,
          firstName,
          lastName,
          zip
        )
      ),
    });
    const result = await response.json();

    if (!response.ok) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('LookUp API:', respErr);

      return { Error: respErr };
    }

    return {
      Authentication: auth,
      RequestId: result.RequestId,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('LookUp Fetch:', error);
    return { Error: error };
  }
};

/**
 * LookUpQuery endpoint get rates matches.
 * @function
 *
 * @param {string} requestId customer rates request identifier
 *
 * @return {QueryParams} Rates payload
 */
export const apiLookUpQuery = async requestId => {
  try {
    const response = await fetch(urls.lookUpQuery, {
      method: 'POST',
      body: JSON.stringify(baseQueryConfig(requestId)),
    });
    const result = await response.json();

    if (!response.ok) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('LookUpQuery API:', respErr);

      return { Error: respErr };
    }

    if (!result.Finished) {
      await wait(2000);
      return apiLookUpQuery(requestId);
    }

    return {
      ...result.Matches[0],
      CashOut: 0, //result.Matches[0].CashOut.replace('$', '').replace(/,/g, ''),
      ClientCode: result.Matches[0]?.ClientCode,
      CallNumber: result.Matches[0]?.CallNumber,
      DwellingType: 'Primary', // TODO: what if this option is disabled in Storyblok
      Fico: result.Matches[0]?.Fico,
      FirstName: result.Matches[0]?.FirstName,
      Hours: result.Matches[0]?.HoursLong,
      LastName: result.Matches[0]?.LastName,
      License: result.Matches[0]?.LicenseLong,
      LoanAmount:
        result.Matches[0]?.LoanAmtNew?.replace('$', '').replace(/,/g, '') ?? '',
      AmortTerm: ['Yr15', 'Yr30'], //[formatTerm(result.Matches[0].Years)],
      LoanPurpose: 'Refi', // TODO: what if this option is disabled in Storyblok
      OfferId: result.Matches[0]?.OfferId,
      OfferWeb: result.Matches[0]?.OfferWeb,
      Points: '1', // TODO: what if this option is disabled in Storyblok
      PropertyType: 'SingleFamily', // TODO: what if this option is disabled in Storyblok
      PropertyValue:
        result.Matches[0]?.MarketValue?.replace('$', '').replace(/,/g, '') ??
        '',
      ResNum: result.Matches[0]?.ResNum,
      Veteran: false,
      RequestId: result.RequestId,
      Zip: result.Matches[0]?.Zip,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('LookUpQuery Fetch:', error);
    return { Error: error };
  }
};

/**
 * Rates endpoint get RequestId.
 * @function
 *
 * @param {QueryParams} params customer approval number
 *
 * @return {QueryBody} LookUpQuery payload
 */
export const apiRates = async params => {
  try {
    const response = await fetch(urls.rates, {
      method: 'POST',
      body: JSON.stringify(ratesConfig(params)),
    });
    const result = await response.json();

    if (!response.ok) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('Rates API:', respErr);

      return { Error: respErr };
    }

    return {
      Authentication: auth,
      RequestId: result.RequestId,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Rates Fetch:', error);
    return { Error: error };
  }
};

/**
 * Rates data
 * @function
 *
 * @param {string} requestId customer rates request identifier
 *
 * @return {RatesData} RatesQuery payload
 */
export const apiRatesQuery = async requestId => {
  try {
    if (!requestId) {
      return { Error: 'Rates Query: Missing requestId' };
    }

    const date = new Date();
    const minutes = 15;
    const response = await fetch(urls.ratesQuery, {
      method: 'POST',
      body: JSON.stringify(baseQueryConfig(requestId)),
    });
    const result = await response.json();

    if (!response.ok) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('RatesQuery API:', respErr);

      return { Error: respErr };
    }

    if (!result.Finished) {
      await wait(2000);
      // return apiRatesQuery(requestId);
    }

    return {
      offers: result.Products.sort((a, b) => (a.Rate > b.Rate ? 1 : -1)),
      lock: addMinutes(date, result.RatesLock || minutes),
      timeout: addMinutes(date, result.RatesTimeout || minutes),
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('RatesQuery Fetch:', error);
    return { Error: error };
  }
};

/**
 * Load Details data
 * @function
 *
 * @param {string} productId loan product identifier
 *
 * @return {obect} offer details
 */
export const apiDetails = async productId => {
  try {
    const response = await fetch(urls.details, {
      method: 'POST',
      body: JSON.stringify(detailsConfig(productId)),
    });
    const result = await response.json();

    if (!response.ok) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('Details API:', respErr);

      return { Error: respErr };
    }

    return {
      ...result.Person,
      ...result.Product,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Details Fetch:', error);
    return { Error: error };
  }
};

/**
 * Application endpoint; lock-in rates.
 * @function
 *
 * @param {string} email
 * @param {string} password
 * @param {string} phone
 * @param {string} offerId
 * @param {string} productId
 *
 * @return {string} RequestId for ApplicationQuery request
 */
export const apiApplication = async (
  email,
  password,
  phone,
  offerId,
  productId
) => {
  try {
    const response = await fetch(urls.app, {
      method: 'POST',
      body: JSON.stringify(
        appConfig(email, password, phone, offerId, productId)
      ),
    });
    const result = await response.json();

    if (!response.ok) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('Application API:', respErr);

      return { Error: respErr };
    }

    return { RequestId: result.RequestId, Url: result.Url };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Application Fetch:', error);
    return { Error: error };
  }
};

/**
 * ApplicationQuery endpoint; lock-in rates.
 * @function
 *
 * @param {string} requestId customer rates request identifier
 *
 * @return {AppResponse} ApplicationQuery request response
 */
export const apiApplicationQuery = async requestId => {
  try {
    const response = await fetch(urls.appQuery, {
      method: 'POST',
      body: JSON.stringify(baseQueryConfig(requestId)),
    });
    const result = await response.json();

    if (!response.ok) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('ApplicationQuery API:', respErr);

      return { Error: respErr };
    }

    if (!result.Finished) {
      await wait(2000);
      return apiApplicationQuery(requestId);
    }

    return {
      RequestId: result.RequestId,
      Url: result.Url,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ApplicationQuery Fetch:', error);
    return { Error: error };
  }
};

/**
 * Resnum endpoint get RequestId.
 * @function
 *
 * @param {string} creative  request entry creative
 * @param {string} dataset   request entry dataset
 * @param {string} source    request source
 * @param {string} resNum    customer approval number
 *
 * @return {QueryBody} ResNumQuery payload
 */
export const apiResNumRates = async (
  creative,
  zip,
  dataset,
  source,
  resNum
) => {
  var api_url = zip ? urls.resNumRates : urls.resNumRatesEx;
  try {
    const response = await fetch(api_url, {
      method: 'POST',
      body: JSON.stringify(
        resNumRatesConfig(creative, zip, dataset, source, resNum)
      ),
    });

    const result = await response.json();

    if (!response.ok || result.Matches.length == 0) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('ResNumRates API:', respErr);

      return { Error: respErr };
    }

    return {
      Authentication: auth,
      RequestId: result.RequestId,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ResNumRates Fetch:', error);
    return { Error: error };
  }
};

/**
 * ResNumQuery endpoint get rates matches.
 * @function
 *
 * @param {string} requestId request identifier
 *
 * @return {QueryParams} Rates payload
 */
export const apiResNumRatesQuery = async requestId => {
  try {
    const response = await fetch(urls.resNumRatesQuery, {
      method: 'POST',
      body: JSON.stringify(baseQueryConfig(requestId)),
    });

    const result = await response.json();

    if (!response.ok) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('ResNumRatesQuery API:', respErr);

      return { Error: respErr };
    }

    if (!result.Finished) {
      await wait(2000);
      return apiResNumRatesQuery(requestId);
    }

    return {
      ...result.Matches[0],
      CashOut: result.Matches[0]?.CashOut,
      ClientCode: result.Matches[0]?.ClientCode,
      CallNumber: result.Matches[0]?.CallNumber,
      DwellingType: 'Primary', // TODO: what if this option is disabled in Storyblok
      Fico: result.Matches[0]?.Fico,
      FirstName: result.Matches[0]?.FirstName,
      Hours: result.Matches[0]?.HoursLong,
      LastName: result.Matches[0]?.LastName,
      License: result.Matches[0]?.LicenseLong,
      LoanAmount: result.Matches[0]?.LoanAmtCurrent?.replace('$', '')?.replace(
        /,/g,
        ''
      ),
      ApiMessage: result?.Message,
      AmortTerm: ['Yr15', 'Yr30'], //[formatTerm(result.Matches[0].Years)],
      LoanPurpose: 'Refi', // TODO: what if this option is disabled in Storyblok
      OfferId: result.Matches[0]?.OfferId,
      OfferWeb: result.Matches[0]?.OfferWeb,
      Points: '1', // TODO: what if this option is disabled in Storyblok
      PropertyType: 'SingleFamily', // TODO: what if this option is disabled in Storyblok
      PropertyValue: result.Matches[0]?.MarketValue?.replace('$', '').replace(
        /,/g,
        ''
      ),
      ResNum: result.Matches[0]?.ResNum,
      Veteran: false,
      RequestId: result.RequestId,
      Zip: result.Matches[0]?.Zip,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ResNumRatesQuery Fetch:', error);
    return { Error: error };
  }
};

export const apiResNumRatesExQuery = async requestId => {
  try {
    const response = await fetch(urls.resNumRatesExQuery, {
      method: 'POST',
      body: JSON.stringify(baseQueryConfig(requestId)),
    });

    const result = await response.json();

    if (!response.ok) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('ResNumRatesQuery API:', respErr);

      return { Error: respErr };
    }

    if (!result.Finished) {
      await wait(2000);
      return apiResNumRatesExQuery(requestId);
    }

    return {
      ...result.Matches[0],
      CashOut: result.Matches[0]?.CashOut,
      ClientCode: result.Matches[0]?.ClientCode,
      CallNumber: result.Matches[0]?.CallNumber,
      DwellingType: 'Primary', // TODO: what if this option is disabled in Storyblok
      Fico: result.Matches[0]?.Fico,
      FirstName: result.Matches[0]?.FirstName,
      Hours: result.Matches[0]?.HoursLong,
      LastName: result.Matches[0]?.LastName,
      License: result.Matches[0]?.LicenseLong,
      LoanAmount: result.Matches[0]?.LoanAmtCurrent?.replace('$', '')?.replace(
        /,/g,
        ''
      ),
      ApiMessage: result?.Message,
      AmortTerm: ['Yr15', 'Yr30'], //[formatTerm(result.Matches[0].Years)],
      LoanPurpose: 'Refi', // TODO: what if this option is disabled in Storyblok
      OfferId: result.Matches[0]?.OfferId,
      OfferWeb: result.Matches[0]?.OfferWeb,
      Points: '1', // TODO: what if this option is disabled in Storyblok
      PropertyType: 'SingleFamily', // TODO: what if this option is disabled in Storyblok
      PropertyValue: result.Matches[0]?.MarketValue?.replace('$', '').replace(
        /,/g,
        ''
      ),
      ResNum: result.Matches[0]?.ResNum,
      Veteran: false,
      RequestId: result.RequestId,
      Zip: result.Matches[0]?.Zip,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ResNumRatesQuery Fetch:', error);
    return { Error: error };
  }
};

export const apiValidateZipQuery = async (requestId, resNum, zip) => {
  try {
    const response = await fetch(urls.validateZipQuery, {
      method: 'POST',
      body: JSON.stringify(zipQueryConfig(requestId, resNum, zip)),
    });

    const result = await response.json();

    if (!response.ok) {
      const respErr = `${response.status} - ${response.type}`;

      // eslint-disable-next-line no-console
      console.error('validateZip API:', respErr);

      return { Error: respErr };
    }

    return result?.Result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('validateZip Fetch:', error);
    return { Error: error };
  }
};
