import React from 'react';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { combineReducers } from 'redux-immutable';
import PropTypes from 'prop-types';

import sagas from './sagas';
import { REDUCER_NAME } from './constants';
import { loadState, saveState } from './sessionState';
import { yapaReducer } from './reducer';

const sagaMiddleware = createSagaMiddleware();
const persistedState = loadState();
const reducers = combineReducers({
  [REDUCER_NAME]: yapaReducer,
});
const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(sagas);
store.subscribe(() => saveState(store.getState()));

const ReduxProvider = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);

ReduxProvider.propTypes = {
  element: PropTypes.node,
};

export default ReduxProvider;
