import wrapWithProvider from './src/base/store/reduxWrapper';

import './src/base/styles/reset.css';
import './src/base/styles/fonts.css';
import './src/base/styles/global.css';
import './src/base/styles/typography.css';

// Load Service Worker on production only.
export const registerServiceWorker = () => {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  return true;
};

// Load Redux store
export const wrapRootElement = wrapWithProvider;

export const onRouteUpdate = () => {
  // Don't track while developing.
  if (process.env.NODE_ENV === 'production' && typeof fbq === 'function') {
    // eslint-disable-next-line no-undef
    fbq('track', 'ViewContent');
  }
};
