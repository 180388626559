import { fromJS } from 'immutable';

import * as constants from './constants';

export const initialState = () => {
  return fromJS({
    [constants.APPLICATION]: {
      RequestId: '',
      URL: '',
    },
    [constants.ARE_RATES_OUTDATED]: false,
    [constants.IDR]: false,
    [constants.ILR]: false,
    [constants.IRR]: false,
    [constants.ERROR]: {
      type: '',
      isError: false,
    },
    [constants.EXPIRATION]: {
      lock: '',
      timeout: '',
    },
    [constants.LOADING]: {
      type: '',
      isLoading: false,
    },
    [constants.OFFER_DETAILS]: {},
    [constants.OFFER_ID]: '',
    [constants.OFFER_WEB]: '',
    [constants.OFFER_INDEX]: 0,
    [constants.PERSON]: {
      ResNum: '',
      Zip: '',
    },
    [constants.PRODUCT_ID]: '',
    [constants.RATES]: [],
    [constants.RATES_PARAMS]: {
      CallNumber: '',
      CashOut: '',
      DwellingType: '',
      Fico: '',
      FirstName: '',
      Hours: '',
      LastName: '',
      License: '',
      ApiMessage: '',
      LoanAmount: '',
      AmortTerm: [],
      LoanPurpose: '',
      OfferId: '',
      Points: '',
      PropertyType: '',
      PropertyValue: '',
      Veteran: false,
      RequestId: '',
      Zip: '',
    },
    [constants.BUTTON_LOADING]: false,
    [constants.REFRESH_UPDATE]: true,
    [constants.RES_NUM]: 0,
    [constants.REQUEST_ID]: '',
    [constants.SUCCESS]: {
      type: '',
      didSucceed: false,
    },
    [constants.UPDATE]: {
      date: '',
      fiveAhead: '',
      fiveBehind: '',
      iso: '',
      relative: '',
      standard: '',
      tenAhead: '',
      tenBehind: '',
    },
  });
};

export const yapaReducer = (state = initialState(), action) => {
  switch (action.type) {
    case constants.RESET:
      return initialState();

    case constants.SET_APPLICATION:
      return state.set(constants.APPLICATION, action.payload);

    case constants.SET_ARE_RATES_OUTDATED:
      return state.set(constants.ARE_RATES_OUTDATED, action.payload);

    case constants.SET_IDR:
      return state.set(constants.IDR, action.payload);

    case constants.SET_ILR:
      return state.set(constants.ILR, action.payload);

    case constants.SET_IRR:
      return state.set(constants.IRR, action.payload);

    case constants.SET_ERROR:
      return state.set(constants.ERROR, action.payload);

    case constants.SET_EXPIRATION:
      return state.set(constants.EXPIRATION, action.payload);

    case constants.SET_LOADING:
      return state.set(constants.LOADING, action.payload);

    case constants.SET_OFFER_DETAILS:
      return state.set(constants.OFFER_DETAILS, action.payload);

    case constants.SET_OFFER_ID:
      return state.set(constants.OFFER_ID, action.payload);

    case constants.SET_OFFER_WEB:
      return state.set(constants.OFFER_WEB, action.payload);

    case constants.SET_OFFER_INDEX:
      return state.set(constants.OFFER_INDEX, action.payload);

    case constants.SET_PERSON:
      return state.set(constants.PERSON, action.payload);

    case constants.SET_PRODUCT_ID:
      return state.set(constants.PRODUCT_ID, action.payload);

    case constants.SET_RATES:
      return state.set(constants.RATES, action.payload);

    case constants.SET_RATES_PARAMS:
      return state.set(constants.RATES_PARAMS, action.payload);

    case constants.SET_REFRESH_UPDATE:
      return state.set(constants.REFRESH_UPDATE, action.payload);

    case constants.SET_RES_NUM:
      return state.set(constants.RES_NUM, action.payload);

    case constants.SET_REQUEST_ID:
      return state.set(constants.REQUEST_ID, action.payload);

    case constants.SET_SUCCESS:
      return state.set(constants.SUCCESS, action.payload);

    case constants.SET_UPDATE:
      return state.set(constants.UPDATE, action.payload);

    case constants.SET_BUTTON_LOADING:
      return state.set(constants.BUTTON_LOADING, action.payload);

    default:
      return state;
  }
};
