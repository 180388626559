// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-firm-offers-js": () => import("./../../../src/components/templates/firm-offers.js" /* webpackChunkName: "component---src-components-templates-firm-offers-js" */),
  "component---src-components-templates-personalized-offers-js": () => import("./../../../src/components/templates/personalized-offers.js" /* webpackChunkName: "component---src-components-templates-personalized-offers-js" */),
  "component---src-components-templates-storyblok-entry-js": () => import("./../../../src/components/templates/storyblok-entry.js" /* webpackChunkName: "component---src-components-templates-storyblok-entry-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

