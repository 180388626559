const getOrdinal = number => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const value = number % 100;

  return number + (suffix[(value - 20) % 10] || suffix[value] || suffix[0]);
};

export const formatDate = () => {
  const now = new Date();
  const raw = new Date();

  const fiveBehindRaw = now.setMinutes(now.getMinutes() - 5);
  const tenBehindRaw = now.setMinutes(now.getMinutes() - 10);
  const fiveAheadRaw = now.setMinutes(now.getMinutes() + 5);
  const tenAheadRaw = now.setMinutes(now.getMinutes() + 10);

  const offset = raw.getTimezoneOffset() * 60000;

  const fiveBehind = new Date(fiveBehindRaw - offset)
    .toISOString()
    .slice(0, -5);
  const tenBehind = new Date(tenBehindRaw - offset).toISOString().slice(0, -5);
  const tenAhead = new Date(tenAheadRaw - offset).toISOString().slice(0, -5);
  const fiveAhead = new Date(fiveAheadRaw - offset).toISOString().slice(0, -5);

  const localDate = new Date(raw - offset);
  const isoDate = localDate.toISOString().slice(0, -5);
  const standardDate = raw.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  const md = raw.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZoneName: 'short',
  });

  const tm = raw
    .toLocaleString('en-US', {
      timeStyle: 'short',
    })
    .replace(
      /(\d{1,2}\/\d{1,2}\/\d{4},\s)?(\d{1,2}:\d{2})(:\d{2})?\s([a-zA-Z]{2})/g,
      '$2 $4'
    );

  const relativeDate = md.replace(
    /([a-zA-z]{3})\s(\d{1,2}).*(\d{4}),\s([A-Z]{3}.*)/g,
    (match, p1, p2, p3, p4) =>
      [p1, getOrdinal(p2), `${p3} at`, tm, `(${p4})`].join(' ')
  );

  const dates = {
    tenBehind,
    fiveBehind,
    date: localDate,
    iso: isoDate,
    relative: relativeDate,
    standard: standardDate,
    fiveAhead,
    tenAhead,
    offset,
  };

  return dates;
};
